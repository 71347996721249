<template>
  <div class="pt-180">
    <center>
      <h1>{{ $t("404_error") }}</h1>
      <h2>{{ $t("wrong_page") }}</h2>
    </center>
  </div>
</template>
<script>
export default {
  name: 'PageNotFound',
}
</script>